import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import ScreenErrorBoundary, {ErrorScreenOptions} from "../components/system/ScreenErrorBoundary";

import PermissionDeniedScene from "../scenes/system/permission-denied";
import TelegramBrowserScene from "../scenes/system/tg-browser-not-supported";

type props = React.PropsWithChildren<{
  extra?: React.ReactNode,
}>

type ErrorListener = (e: ErrorEvent) => any

type context = {
  addErrorListener: (listener: ErrorListener, prepend?: boolean) => void,
  removeErrorListener: (listener: ErrorListener) => boolean,
  bindErrorListener: (listener: ErrorListener, prepend?: boolean) => () => void,
}

const Context = React.createContext<context>({
  addErrorListener: () => console.error("[NotImplemented]"),
  removeErrorListener: () => {
    console.error("[NotImplemented]")
    return false;
  },
  bindErrorListener: () => {
    console.error("[NotImplemented]");
    return () => console.error("[NotImplemented]")
  }
});

export const getDefaultErrorScreens = () => new Map<string, ErrorScreenOptions>([
  ["PERMISSION_DENIED", {
    screen: PermissionDeniedScene,
    stopErrorProcessing: true
  }],
  ["Method not found", {
    screen: TelegramBrowserScene,
    stopErrorProcessing: true
  }]
])

export default function ErrorBoundary({children, extra}: props) {

  const [errorListeners, setErrorListeners] = useState<ErrorListener[]>([])

  const addErrorListener = useCallback<context["addErrorListener"]>((listener, prepend) => {
    setErrorListeners(prevErrorListeners => {
      const newErrorListeners = [...prevErrorListeners]
      if (prepend) {
        newErrorListeners.unshift(listener)
      } else {
        newErrorListeners.push(listener)
      }
      return newErrorListeners
    })
  }, []);

  const removeErrorListener = useCallback<context["removeErrorListener"]>((listener) => {
    let index: number;

    setErrorListeners(prevErrorListeners => {
      index = prevErrorListeners.indexOf(listener);
      const newErrorListeners = [...prevErrorListeners]
      if (index! > -1) {
        newErrorListeners.splice(index!, 1);
      }
      return newErrorListeners
    })

    return index! > -1;
  }, []);

  const bindErrorListener = useCallback<context["bindErrorListener"]>((listener, prepend) => {
    addErrorListener(listener, prepend)
    return () => removeErrorListener(listener)
  }, [addErrorListener, removeErrorListener]);

  useEffect(() => {
    const onErrorEvent: ErrorListener = (e) => {
      errorListeners.some(listener => listener(e))
    }

    window.addEventListener("error", onErrorEvent)
    return () => {
      window.removeEventListener("error", onErrorEvent)
    }
  }, [errorListeners]);

  const errorScreens = useMemo(() => (getDefaultErrorScreens()), [])

  return (
    <Context.Provider value={{
      addErrorListener,
      removeErrorListener,
      bindErrorListener
    }}>
      <ScreenErrorBoundary
        extra={extra}
        errorScreens={errorScreens}
      >
        {children}
      </ScreenErrorBoundary>
    </Context.Provider>
  )
}

export function useErrorContext(): context {
  return useContext(Context);
}
