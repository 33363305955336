import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import ErrorScreen from "./generic-error-screen";
import {analyticsSendEvent} from "../../libs/analytics";

export default function TelegramBrowserScene({children}: React.PropsWithChildren<{}>) {
  const {t} = useTranslation();

  useEffect(() => {
    analyticsSendEvent("telegramBrowserUsed");
  }, []);

  return (
    <ErrorScreen
      documentTitle={t("system.telegramBrowserUsed.title")}
      title={t("system.telegramBrowserUsed.h1")}
      description={t("system.telegramBrowserUsed.desc")}
      withoutImage
    >
      {children}
    </ErrorScreen>
  )
}
