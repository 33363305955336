/* eslint-disable max-lines */
import React, {useMemo} from "react";
import useVisibility from "../../hooks/useVisibility";
import {useTranslation} from "react-i18next";

import {
  EducationSessionRecordState, EducationSessionResult, LanguageCode, Lesson, LessonMode, Maybe
} from "../../schema";
import {formatDuration} from "../../time";
import ReactLink from "../ReactLink";
import FullscreenVideo from "../../ui/fullscreenvideo";
import RecordLink from "../RecordLink";
import Link from "../../ui/link";

import {reverse} from "../../routing";

import cn from "classnames"
import useAdaptiveClasses from "../../hooks/useAdaptiveClasses";
import classes from "./ResultsDisplay.module.css";

type props = Omit<React.ComponentProps<"div">, "results"> & {
  children?: React.ReactNode,
  lesson: Pick<Lesson, "id" | "mode" | "languageCode" | "evaluateQuizScore" | "showDetailedResults">,
  results: Pick<EducationSessionResult,
    "passed" | "score" | "time" | "usedHints" | "record" | "recordState" |
    "generalSentimentNeutral" | "generalSentimentPositive" | "generalSentimentNegative" |
    "parametricResults" | "generalEmotionAnger" | "generalEmotionDisgust" | "generalEmotionEnthusiasm" |
    "generalEmotionFear" | "generalEmotionGuilt" | "generalEmotionJoy" | "generalEmotionNeutral" |
    "generalEmotionSadness" | "generalEmotionShame" | "generalEmotionSurprise"
  >,
  canDisplayResults?: boolean,
  adaptive?: boolean,
  hideTitle?: boolean,
  hideHelpText?: boolean,
  resultMessage?: string,
  showDetailed: boolean,
  customScores: boolean,
  matchedDescription?: string,
  multiDescriptions?: {parameter: string | undefined, message: Maybe<string> | undefined}[]
}

const ResultsDisplay = function ({
                                   className: classNameEx,
                                   children,
                                   lesson,
                                   results,
                                   canDisplayResults,
                                   adaptive,
                                   hideTitle,
                                   hideHelpText,
                                   resultMessage,
                                   showDetailed,
                                   customScores,
                                   matchedDescription,
                                   multiDescriptions,
                                   ...props
                                 }: props) {
  const {t} = useTranslation();
  const lessonId = lesson.id;
  const lessonMode = lesson.mode;
  const evaluateQuizScore = lesson.evaluateQuizScore;

  const [videoVisible, showVideo, hideVideo] = useVisibility();

  const ac = useAdaptiveClasses(classes, adaptive)

  let displayFields: string[] = useMemo(() => {
    switch (lessonMode) {
      case LessonMode.CHOICES_TEST_TWO_STEP:
      case LessonMode.CHOICE_BASED_STUDY:
        return ["score", "time"]
      case LessonMode.STUDY_THREE_STEP:
      case LessonMode.STUDY_TWO_STEP:
        return ["score", "time", "hints"]
      case LessonMode.INTERVIEW:
      case LessonMode.CUSTOM_PARAMETERS_TEST:
        return ["time"]
      case LessonMode.QUIZ:
        if (evaluateQuizScore) {
          return ["score", "time"]
        }
        return ["time"]
      /* istanbul ignore next */
      default:
        console.warn("Results: unknown lesson mode")
        return ["score", "time"];
    }
  }, [lessonMode, evaluateQuizScore]);

  const passedText = (
    (lessonMode === LessonMode.QUIZ && !evaluateQuizScore) ||
      lessonMode === LessonMode.CHOICES_WITH_CUSTOM_SCORES ||
      lessonMode === LessonMode.CUSTOM_PARAMETERS_TEST
    ) ? t("components.ResultsDisplay.passed.textAlt")
    : t("components.ResultsDisplay.passed.text")

  const emotions = useMemo(() => {
    const values = [
      {
        "label": "emotionAnger",
        "score": (results.generalEmotionAnger ? results.generalEmotionAnger : 0)
      },
      {
        "label": "emotionDisgust",
        "score": (results.generalEmotionDisgust ? results.generalEmotionDisgust : 0)
      },
      {
        "label": "emotionEnthusiasm",
        "score": (results.generalEmotionEnthusiasm ? results.generalEmotionEnthusiasm : 0)
      },
      {
        "label": "emotionFear",
        "score": (results.generalEmotionFear ? results.generalEmotionFear : 0)
      },
      {
        "label": "emotionGuilt",
        "score": (results.generalEmotionGuilt ? results.generalEmotionGuilt : 0)
      },
      {
        "label": "emotionJoy",
        "score": (results.generalEmotionJoy ? results.generalEmotionJoy : 0)
      },
      {
        "label": "emotionNeutral",
        "score": (results.generalEmotionNeutral ? results.generalEmotionNeutral : 0)
      },
      {
        "label": "emotionSadness",
        "score": (results.generalEmotionSadness ? results.generalEmotionSadness : 0)
      },
      {
        "label": "emotionShame",
        "score": (results.generalEmotionShame ? results.generalEmotionShame : 0)
      },
      {
        "label": "emotionSurprise",
        "score": (results.generalEmotionSurprise ? results.generalEmotionSurprise : 0)
      },
    ]

    const sortedValues = values.sort((a, b) => a.score - b.score).reverse();
    return sortedValues.slice(0, 3)

  }, [results])

  return (
    <div className={cn(classes.root, classNameEx)} {...props}>
      {!hideTitle && (
        <h2 className={ac.title}>
          {results.passed ? t("components.ResultsDisplay.passed.title") : t("components.ResultsDisplay.failed.title")}
        </h2>
      )}
      {!hideHelpText && (
        <span className={ac.helpText}>
          {results.passed ? passedText : t("components.ResultsDisplay.failed.text")}
        </span>
      )}
      <div className={ac.scoresRow}>
        {displayFields.includes("time") && (
          <div className={ac.score}>
            <span className={ac.scoreName}>{t("components.ResultsDisplay.scores.time")}</span>
            <span className={ac.scoreValue}>
              {results?.time !== undefined ? formatDuration(results.time) : ""}
            </span>
          </div>
        )}

        {displayFields.includes("score") && (
          <div className={ac.score}>
            <span className={ac.scoreName}>{t("components.ResultsDisplay.scores.score")}</span>
            <span className={ac.scoreValue}>{results.score}
              {lessonMode === LessonMode.CHOICES_WITH_CUSTOM_SCORES
                || lessonMode === LessonMode.CUSTOM_PARAMETERS_TEST ? "" : " %"
              }
            </span>
          </div>
        )}

        {displayFields.includes("hints") && (
          <div className={ac.score}>
            <span className={ac.scoreName}>{t("components.ResultsDisplay.scores.hints")}</span>
            <span className={ac.scoreValue}>{results.usedHints}</span>
          </div>
        )}
      </div>
      {lesson.mode === LessonMode.CUSTOM_PARAMETERS_TEST && (
          <div className={ac.scoresRow}>
            {results.parametricResults.map((result) => (
              <div className={ac.score}>
                <span className={ac.scoreName}>{result.parameterName}</span>
                <span className={ac.scoreValue}>{result.score}</span>
              </div>
            ))}
          </div>
        )}

      {resultMessage && (
        <div
          className={showDetailed
            ? classes.resultMessageContainer
            : classes.resultMessageContainerCenter}
        >
          {resultMessage}
        </div>
      )}
      {(!multiDescriptions && (customScores || lesson.mode === LessonMode.CUSTOM_PARAMETERS_TEST)) && (
        <div
          className={showDetailed
          ? classes.resultMessageContainer
          : classes.resultMessageContainerCenter}
        >
          {matchedDescription}
        </div>
      )}
      {multiDescriptions && (
          multiDescriptions.map(desc =>
            desc.message ? (
              <div className={classes.resultMessageContainer}>
              {desc.parameter + ": " + desc.message}
            </div>
            ) : <></>)
      )}

      {lesson.languageCode === LanguageCode.RU_RU && lesson.showDetailedResults && (
        <div>
          <span className={ac.helpText}>
            {t("components.ResultsDisplay.generalSentiment")}
          </span>
          <div className={ac.scoresRow}>
            <div className={ac.score}>
              <span className={ac.scoreName}>{t("components.ResultsDisplay.sentiment.positive")}</span>
              <span className={ac.scoreValue}>
                {results?.generalSentimentPositive ?? "0"}{"%"}
              </span>
            </div>
            <div className={ac.score}>
              <span className={ac.scoreName}>{t("components.ResultsDisplay.sentiment.neutral")}</span>
              <span className={ac.scoreValue}>
                {results?.generalSentimentNeutral ?? "0"}{"%"}
              </span>
            </div>
            <div className={ac.score}>
              <span className={ac.scoreName}>{t("components.ResultsDisplay.sentiment.negative")}</span>
              <span className={ac.scoreValue}>
                {results?.generalSentimentNegative ?? "0"}{"%"}
              </span>
            </div>
          </div>
          <div className={ac.scoresRow}>
            <div className={ac.score}>
              <span className={ac.scoreName}>{t("components.emotions." + emotions[0].label)}</span>
              <span className={ac.scoreValue}>
                {emotions[0].score ?? "0"}{"%"}
              </span>
            </div>
            <div className={ac.score}>
              <span className={ac.scoreName}>{t("components.emotions." + emotions[1].label)}</span>
              <span className={ac.scoreValue}>
                {emotions[1].score ?? "0"}{"%"}
              </span>
            </div>
            <div className={ac.score}>
              <span className={ac.scoreName}>{t("components.emotions." + emotions[2].label)}</span>
              <span className={ac.scoreValue}>
                {emotions[2].score ?? "0"}{"%"}
              </span>
            </div>
          </div>
        </div>
      )}

      {canDisplayResults ? (
        <ReactLink href={reverse("playerResults", {lessonId: lessonId})} className={ac.resultsLink}>
          {t("components.ResultsDisplay.results")}
        </ReactLink>
      ) : (results.recordState !== EducationSessionRecordState.SKIPPED) && (
        <>
          <RecordLink as={Link}
            className={ac.recordLink}
            state={results.recordState}
            onClick={showVideo}
          />
          {videoVisible && <FullscreenVideo src={results.record ?? undefined} onClose={hideVideo}/>}
        </>
      )}

      {children}
    </div>
  )
}

export default ResultsDisplay;
