/* eslint-disable max-lines */
import React, {ChangeEventHandler, ComponentProps, useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useFormState} from "../../hooks/useFormState";
import useVisibility from "../../hooks/useVisibility";

import {Course, DashboardDataFilterInput, DashboardDataType, Group, Lesson} from "../../schema";
import {BooleanForm, booleanFormEnumToValue, booleanFormValueToEnum} from "../../utils";
import without from "lodash/without";

import {HelpCircleIcon} from "../../ui/icons";
import Input from "../../ui/input";
import Select from "../../ui/select";
import WithTooltip from "../../ui/tooltip";
import Checkbox from "../../ui/checkbox";
import Link from "../../ui/link";
import {ChartMeasurementUnits, ChartVisualization} from "../../types";

import classes from "./DashboardFilterForm.module.css";


export type QueryData = {
  lessons: Pick<Lesson, "id" | "name">[],
  courses: Pick<Course, "id" | "name">[],
  groups: Pick<Group, "id" | "name">[]
}

type ExtProps = {
  dataType: DashboardDataType,
  visualization: ChartVisualization,
  measurementUnits: ChartMeasurementUnits,
} & DashboardDataFilterInput

type Props = Omit<ComponentProps<"form">, "children" | "onSubmit"> & {
  lessons?: QueryData["lessons"],
  courses?: QueryData["courses"],
  groups?: QueryData["groups"],
  initialValues?: DashboardDataFilterInput,
  onSave: (data: ExtProps) => void,
  disabled?: boolean,
}

type formState = {
  dataType: DashboardDataType,
  email: DashboardDataFilterInput["email"],
  active: BooleanForm,
  lessonsIds: NonNullable<DashboardDataFilterInput["lessonsIds"]>,
  coursesIds: NonNullable<DashboardDataFilterInput["coursesIds"]>,
  groupsIds: NonNullable<DashboardDataFilterInput["groupsIds"]>,
  daysAmount: number,
  measurementUnits: ChartMeasurementUnits,
  visualization: ChartVisualization,
}

export default function DashboardFilterForm(
  {initialValues, lessons, courses, groups, onSave, disabled, ...props}: Props) {
  const {t} = useTranslation();

  const [allLessonsVisible, showAllLessons, showLessLessons] = useVisibility();
  const [allCoursesVisible, showAllCourses, showLessCourses] = useVisibility();
  const [allGroupsVisible, showAllGroups, showLessGroups] = useVisibility();

  const formState = useFormState<formState>({
    initialValues: {
      dataType: DashboardDataType.STARTED_TO_ALL,
      email: initialValues?.email,
      active: booleanFormValueToEnum(initialValues?.active ?? undefined),
      lessonsIds: initialValues?.lessonsIds ?? [],
      coursesIds: initialValues?.coursesIds ?? [],
      groupsIds: initialValues?.groupsIds ?? [],
      daysAmount: initialValues?.daysAmount ?? 0,
      measurementUnits: ChartMeasurementUnits.ABS,
      visualization: ChartVisualization.BAR,
    },
    preventDefault: true,
    onChange: (data) => {
      onSave && onSave({
        ...data,
        active: booleanFormEnumToValue(data.active),
      })
    }
  });

  const onGroupSelect = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    const groupId = e.currentTarget.getAttribute("data-group-id")!;
    const checked = e.currentTarget.checked;
    formState.setValues((values) => ({
      groupsIds: checked
        ? values.groupsIds.concat(groupId)
        : without(values.groupsIds, groupId)
    }))
  }, [formState])

  const onLessonSelect = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    const lessonId = e.currentTarget.getAttribute("data-lesson-id")!;
    const checked = e.currentTarget.checked;
    formState.setValues((values) => ({
      lessonsIds: checked
        ? values.lessonsIds.concat(lessonId)
        : without(values.lessonsIds, lessonId)
    }))
  }, [formState])

  const onCourseSelect = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    const courseId = e.currentTarget.getAttribute("data-course-id")!;
    const checked = e.currentTarget.checked;
    formState.setValues((values) => ({
      coursesIds: checked
        ? values.coursesIds.concat(courseId)
        : without(values.coursesIds, courseId)
    }))
  }, [formState])

  const selectedGroups = useMemo(() => {
    if (!groups) {
      return;
    }
    if (!allGroupsVisible && groups.length > 5) {
      return groups.slice(0, 5)
    }
    return groups;
  }, [allGroupsVisible, groups])

  const selectedLessons = useMemo(() => {
    if (!lessons) {
      return;
    }
    if (!allLessonsVisible && lessons.length > 5) {
      return lessons.slice(0, 5)
    }
    return lessons;
  }, [allLessonsVisible, lessons])

  const selectedCourses = useMemo(() => {
    if (!courses) {
      return;
    }
    if (!allCoursesVisible && courses.length > 5) {
      return courses.slice(0, 5)
    }
    return courses;
  }, [allCoursesVisible, courses])

  return (
    <form method="post" onSubmit={formState.submitHandler} {...props}>

      <div className={classes.formItem}>
        <label className={classes.label}>
          {t("components.DashboardFilterForm.dataType.label")}
          {" "}
          <WithTooltip className={classes.help} as='span'
                       helpText={t("components.DashboardFilterForm.dataType.helpText")}>
            <HelpCircleIcon/>
          </WithTooltip>
        </label>
        <WithTooltip className={classes.help} as='span'
                       helpText={t("components.DashboardFilterForm.dataType.helpText")}>
          <Select name="dataType" value={formState.values.dataType ?? DashboardDataType.STARTED_TO_ALL}
                  onChange={formState.changeHandler} disabled={disabled}>
            <option value={DashboardDataType.STARTED_TO_ALL}>
              {t("components.DashboardFilterForm.dataType.type.STARTED_TO_ALL")}
            </option>
            <option value={DashboardDataType.PASSED_TO_STARTED}>
              {t("components.DashboardFilterForm.dataType.type.PASSED_TO_STARTED")}
            </option>
            <option value={DashboardDataType.NO_HINTS_TO_PASSED}>
              {t("components.DashboardFilterForm.dataType.type.NO_HINTS_TO_PASSED")}
            </option>
            <option value={DashboardDataType.SESSIONS}>
              {t("components.DashboardFilterForm.dataType.type.SESSIONS")}
            </option>
            <option value={DashboardDataType.FAIL_REASONS}>
              {t("components.DashboardFilterForm.dataType.type.FAIL_REASONS")}
            </option>
            <option value={DashboardDataType.LESSONS_STATUS}>
              {t("components.DashboardFilterForm.dataType.type.LESSONS_STATUS")}
            </option>
            <option value={DashboardDataType.AVG_SCORE}>
              {t("components.DashboardFilterForm.dataType.type.AVG_SCORE")}
            </option>
            <option value={DashboardDataType.AVG_SCORE_BY_LESSON}>
              {t("components.DashboardFilterForm.dataType.type.AVG_SCORE_BY_LESSON")}
            </option>
            <option value={DashboardDataType.AVG_FEEDBACK}>
              {t("components.DashboardFilterForm.dataType.type.AVG_FEEDBACK")}
            </option>
            <option value={DashboardDataType.AVG_FEEDBACK_BY_LESSON}>
              {t("components.DashboardFilterForm.dataType.type.AVG_FEEDBACK_BY_LESSON")}
            </option>
            <option value={DashboardDataType.AVG_TIME_BY_LESSON}>
              {t("components.DashboardFilterForm.dataType.type.AVG_TIME_BY_LESSON")}
            </option>
            <option value={DashboardDataType.LIKED}>
              {t("components.DashboardFilterForm.dataType.type.LIKED")}
            </option>
            <option value={DashboardDataType.DISLIKED}>
              {t("components.DashboardFilterForm.dataType.type.DISLIKED")}
            </option>
          </Select>
        </WithTooltip>
      </div>

      <div className={classes.formItem}>
        <label className={classes.label}>
          {t("components.DashboardFilterForm.visualization.label")}
          {" "}
          <WithTooltip className={classes.help} as='span'
                       helpText={t("components.DashboardFilterForm.visualization.helpText")}>
            <HelpCircleIcon/>
          </WithTooltip>
        </label>
        <WithTooltip className={classes.help} as='span'
                       helpText={t("components.DashboardFilterForm.visualization.helpText")}>
          <Select name="visualization" value={formState.values.visualization}
                  onChange={formState.changeHandler} disabled={disabled}>
            <option value={ChartVisualization.BAR}>
              {t("components.DashboardFilterForm.visualization.type.BAR")}
            </option>
            <option value={ChartVisualization.DONUT}>
              {t("components.DashboardFilterForm.visualization.type.DONUT")}
            </option>
          </Select>
        </WithTooltip>
      </div>

      <div className={classes.formItem}>
        <label className={classes.label}>
          {t("components.DashboardFilterForm.measurementUnits.label")}
          {" "}
          <WithTooltip className={classes.help} as='span'
                       helpText={t("components.DashboardFilterForm.measurementUnits.helpText")}>
            <HelpCircleIcon/>
          </WithTooltip>
        </label>
        <WithTooltip className={classes.help} as='span'
                       helpText={t("components.DashboardFilterForm.measurementUnits.helpText")}>
          <Select name="measurementUnits" value={formState.values.measurementUnits}
                  onChange={formState.changeHandler} disabled={disabled}>
            <option value={ChartMeasurementUnits.ABS}>
              {t("components.DashboardFilterForm.measurementUnits.type.ABS")}
            </option>
            <option value={ChartMeasurementUnits.PERCENTAGE}>
              {t("components.DashboardFilterForm.measurementUnits.type.PERCENTAGE")}
            </option>
          </Select>
        </WithTooltip>
      </div>

      <div className={classes.formItem}>
        <label className={classes.label}>
          {t("components.MemberRowFilterForm.email.label")}
          {" "}
          <WithTooltip className={classes.help} as='span'
                       helpText={t("components.MemberRowFilterForm.email.helpText")}>
            <HelpCircleIcon/>
          </WithTooltip>
        </label>
        <WithTooltip className={classes.help} as='span'
                       helpText={t("components.MemberRowFilterForm.email.helpText")}>
          <Input
            name="email"
            displaySize="sm"
            placeholder='example@gmail.com'
            value={formState.values.email ?? undefined}
            onChange={formState.changeHandler}
            disabled={disabled}
          />
        </WithTooltip>
      </div>

      <div className={classes.formItem}>
        <label className={classes.label}>
          {t("components.MemberRowFilterForm.active.label")}
          {" "}
          <WithTooltip className={classes.help} as='span'
                       helpText={t("components.MemberRowFilterForm.active.helpText")}>
            <HelpCircleIcon/>
          </WithTooltip>
        </label>
        <WithTooltip className={classes.help} as='span'
                       helpText={t("components.MemberRowFilterForm.active.helpText")}>
          <Select name="active" value={formState.values.active ?? BooleanForm.UNDEFINED}
                  onChange={formState.changeHandler} disabled={disabled}>
            <option value={BooleanForm.UNDEFINED}>
              {t("components.MemberRowFilterForm.active.no")}
            </option>
            <option value={BooleanForm.TRUE}>
              {t("components.MemberRowFilterForm.active.true")}
            </option>
            <option value={BooleanForm.FALSE}>
              {t("components.MemberRowFilterForm.active.false")}
            </option>
          </Select>
        </WithTooltip>
      </div>

      <div className={classes.formItem}>
        <label className={classes.label}>
          {t("components.DashboardFilterForm.time.label")}
          {" "}
          <WithTooltip className={classes.help} as='span'
                       helpText={t("components.DashboardFilterForm.time.helpText")}>
            <HelpCircleIcon/>
          </WithTooltip>
        </label>
        <WithTooltip className={classes.help} as='span'
                       helpText={t("components.DashboardFilterForm.time.helpText")}>
          <Select name="daysAmount" value={formState.values.daysAmount ?? 0}
                  onChange={formState.changeHandler} disabled={disabled}>
            <option value={0}>
              {t("components.DashboardFilterForm.time.all")}
            </option>
            <option value={365}>
              {t("components.DashboardFilterForm.time.year")}
            </option>
            <option value={90}>
              {t("components.DashboardFilterForm.time.3months")}
            </option>
            <option value={30}>
              {t("components.DashboardFilterForm.time.month")}
            </option>
            <option value={7}>
              {t("components.DashboardFilterForm.time.week")}
            </option>
            <option value={1}>
              {t("components.DashboardFilterForm.time.24h")}
            </option>
          </Select>
        </WithTooltip>
      </div>

      {lessons && lessons?.length > 0 && (
        <div className={classes.formItem}>
          <label className={classes.label}>
            {t("components.MemberRowFilterForm.lesson.label")}
            {" "}
            <WithTooltip className={classes.help} as='span'
                        helpText={t("components.MemberRowFilterForm.lesson.helpText")}>
              <HelpCircleIcon/>
            </WithTooltip>
          </label>
          {selectedLessons?.map(({name, id}) => (
            <label key={id} className={classes.checkboxLabel}>
              <Checkbox
                checked={formState.values.lessonsIds.includes(id)}
                onChange={onLessonSelect}
                data-lesson-id={id}
                disabled={disabled}
              />
              <span className={classes.checkboxText}>{name}</span>
            </label>
          ))}
          {lessons && lessons.length > 5 && (
            allLessonsVisible ? (
              <Link onClick={showLessLessons}>{t("components.DashboardFilterForm.lessons.showLess")}</Link>
            ) : (
              <Link onClick={showAllLessons}>{t("components.DashboardFilterForm.lessons.showAll")}</Link>
            )
          )}
        </div>
      )}

      {courses && courses?.length > 0 && (
        <div className={classes.formItem}>
          <label className={classes.label}>
            {t("components.MemberRowFilterForm.course.label")}
            {" "}
            <WithTooltip className={classes.help} as='span'
                        helpText={t("components.MemberRowFilterForm.course.helpText")}>
              <HelpCircleIcon/>
            </WithTooltip>
          </label>
          {selectedCourses?.map(({name, id}) => (
            <label key={id} className={classes.checkboxLabel}>
              <Checkbox
                checked={formState.values.coursesIds.includes(id)}
                onChange={onCourseSelect}
                data-course-id={id}
                disabled={disabled}
              />
              <span className={classes.checkboxText}>{name}</span>
            </label>
          ))}
          {courses && courses.length > 5 && (
            allCoursesVisible ? (
              <Link onClick={showLessCourses}>{t("components.DashboardFilterForm.courses.showLess")}</Link>
            ) : (
              <Link onClick={showAllCourses}>{t("components.DashboardFilterForm.courses.showAll")}</Link>
            )
          )}
        </div>
      )}

      {groups && groups?.length > 0 && (
        <div className={classes.formItem}>
          <label className={classes.label}>
            {t("components.MemberRowFilterForm.groups.label")}
            {" "}
            <WithTooltip className={classes.help} as='span'
                        helpText={t("components.MemberRowFilterForm.groups.helpText")}>
              <HelpCircleIcon/>
            </WithTooltip>
          </label>
          {selectedGroups?.map(({name, id}) => (
            <label key={id} className={classes.checkboxLabel}>
              <Checkbox
                checked={formState.values.groupsIds.includes(id)}
                onChange={onGroupSelect}
                data-group-id={id}
                disabled={disabled}
              />
              <span className={classes.checkboxText}>{name}</span>
            </label>
          ))}
          {groups && groups.length > 5 && (
            allGroupsVisible ? (
              <Link onClick={showLessGroups}>{t("components.MemberRowFilterForm.groups.showLess")}</Link>
            ) : (
              <Link onClick={showAllGroups}>{t("components.MemberRowFilterForm.groups.showAll")}</Link>
            )
          )}
        </div>
      )}
    </form>
  )
}
