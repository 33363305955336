import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";

import classes from "./generic-error-screen.module.css";

import Layout, {Header, Main} from "../../components/Layout";
import ReactDocumentTitle from "../../components/ReactDocumentTitle";
import Container from "../../ui/adaptivecontainer";
import Button from "../../ui/button";
import {reverse} from "../../routing";

type props = {
  children?: React.ReactNode,
  testId?: string
  documentTitle?: string,
  title?: string,
  description?: string,
  withoutImage?: boolean,
}

export default function ErrorScreen({testId, children, documentTitle, title, description, withoutImage}: props) {
  const {t} = useTranslation();

  return (
    <>
      <ReactDocumentTitle title={documentTitle ?? t("system.genericErrorScreen.title")}/>

      <Layout.Display data-testid={testId} className={withoutImage ? classes.layoutRootNoImage : classes.layoutRoot}>
        <Header.Display type="transparent" adaptive/>
        <Main>
          <Container>
            <div className={classes.root}>
              <h1 className={classes.h1}>{title ?? t("system.genericErrorScreen.h1")}</h1>
              <p className={classes.desc}>{description ?? t("system.genericErrorScreen.desc")}</p>
              {children}
            </div>
          </Container>
        </Main>
      </Layout.Display>
    </>
  )
}

const RefreshButton = () => {
  const {t} = useTranslation();

  const refreshHandler = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <Button onClick={refreshHandler}>
      {t("system.genericErrorScreen.refreshPage")}
    </Button>
  )
}

const HomepageLink = () => {
  const {t} = useTranslation();

  return (
    <p><a href={reverse("home")}>{t("system.genericErrorScreen.toHomepage")}</a></p>
  )
}

ErrorScreen.RefreshButton = RefreshButton
ErrorScreen.HomepageLink = HomepageLink
